<template>
  <div class="dashboard-content">
    <!--    <a-row>-->
    <!--      <a-col :span="24">-->
    <!--        <h4><a-icon type="credit-card" /> Аккаунты платежных систем</h4>-->
    <!--      </a-col>-->
    <!--    </a-row>-->
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-5">
        <span class="h5 mb-0"><font-awesome-icon icon="chart-bar"  /> Сводка</span>
        <router-link to="/accounts">
          <a-button type="primary">Аккаунты</a-button>
        </router-link>
      </div>
      <a-table :columns="accountColumns" :dataSource="accountsData" :pagination="true">
        <span slot="account" slot-scope="account, record">
          <router-link
            tag="a"
            class="button"
            :to="{name: 'transactions', params: { accountId: record.id }}"
          >
            <a href="javascript:">{{ record.name }}</a>
          </router-link>
        </span>
        <span slot="transactions_count" slot-scope="transactions_count, record">
          <router-link
            tag="a"
            class="button"
            :to="{name: 'transactions', params: { accountId: record.id }}"
          >
            <a-tooltip title="Всего транзакций" placement="bottom">
              <span><a-tag>{{ transactions_count.total }}</a-tag></span>
            </a-tooltip>
            <a-tooltip title="Оплачено" placement="bottom" v-if="transactions_count.paid>0">
              <span><a-tag color="green">{{ transactions_count.paid }}</a-tag></span>
            </a-tooltip>
          </router-link>
        </span>
        <span slot="psystem" slot-scope="psystem, record">
          <span v-if="psystem === 1"><img src="/resources/images/ps/qiwi-logo.png" height="16" /></span>
          <span v-if="psystem === 2"><img src="/resources/images/ps/ik-logo.png" height="16" /></span>
          <span v-if="psystem === 3"><img src="/resources/images/ps/stripe-logo.png" height="16" /></span>
          {{ record.ps.name }}
        </span>
        <span slot="rotate" slot-scope="rotate, record">
          <span v-if="rotate===1">
            <a-tooltip title="В ротации" placement="bottom">
              <font-awesome-icon icon="play" class="text-success font-size-18" />
<!--              <a-tag color="green"><a-icon type="reload" /> В ротации</a-tag>-->
            </a-tooltip>
          </span>
          <span v-else>
            <a-tooltip title="Остановлен" placement="bottom" v-if="(record.start_rotate && moment(record.start_rotate).isAfter('2030-12-30'))">
              <font-awesome-icon icon="pause" class="text-warning font-size-18" />
            </a-tooltip>
            <a-tooltip title="В ожидании" placement="bottom" v-else>
              <font-awesome-icon icon="pause" class="text-warning font-size-18" />
            </a-tooltip>
          </span>
        </span>
        <span slot="balance" slot-scope="balance, record">
          {{ record.balance }} ₽
        </span>
        <span slot="income" slot-scope="sum">
          <font-awesome-icon icon="sort-amount-down-alt" class="text-success" /> {{ sum.income.total }} ₽
          <a-tooltip title="За сегодня" placement="bottom" v-if="sum.income.today>0">
            <span class="text-success font-size-12">+{{ sum.income.today }} ₽</span>
          </a-tooltip>
        </span>
        <span slot="outcome" slot-scope="sum">
          <font-awesome-icon icon="sort-amount-up-alt" class="text-danger" /> {{ sum.outcome.total }} ₽
          <a-tooltip title="За сегодня" placement="bottom" v-if="sum.outcome.today>0">
            <span class="text-danger font-size-12">-{{ sum.outcome.today }} ₽</span>
          </a-tooltip>
        </span>
        <span slot="is_banned" slot-scope="is_banned">
          <span v-if="is_banned === 1">
            <font-awesome-icon icon="frown" class="font-size-18 text-danger" /> Заблокирован
          </span>
          <span v-else class="text-center" >
            <font-awesome-icon icon="smile" class="font-size-18 text-success" /> Активен
          </span>
        </span>
        <span slot="operation" slot-scope="text, record">
          <div class="text-right">
            <span v-if="rotateLoading && rotateId===record.id"><a-icon type="loading" /></span>
            <span v-else>
              <span v-if="record.in_rotate===0">
                <a href="javascript:" @click="changeRotation(record.id, 1)">
                  <a-tooltip title="В ротацию" placement="bottom">
                    <font-awesome-icon icon="play" class="text-success font-size-18" />
                  </a-tooltip>
                </a>
              </span>
              <span v-else>
                <a href="javascript:" @click="changeRotation(record.id, 0)">
                  <a-tooltip title="Остановить" placement="bottom">
                    <font-awesome-icon icon="pause" class="text-warning font-size-18" />
                  </a-tooltip>
                </a>
              </span>
            </span>
            <span v-if="restrictionsLoading && restrictionsId===record.id" class="ml-3"><a-icon type="loading" /></span>
<!--            <span v-else class="ml-3">-->
<!--              <a href="javascript:" @click="checkRestrictions(record.id)"><a-icon type="check" /> Проверить</a>-->
<!--            </span>-->
            <span class="ml-3" v-if="record.psystem_id === 1 && 1 === 0">
              <a-tooltip title="Вывести" placement="bottom">
                <a href="javascript:" @click="showWithdrawModal(record.id)">
                  <font-awesome-icon icon="money-check-alt" class="text-gray-6 font-size-18" />
                </a>
              </a-tooltip>
            </span>
            <span class="ml-3">
              <a-tooltip title="Обновить" placement="bottom">
                <a href="javascript:" >
                  <font-awesome-icon icon="sync-alt" class="text-gray-6 font-size-18" />
                </a>
              </a-tooltip>
            </span>
          </div>
        </span>
      </a-table>
      <a-modal v-model="newAccountModal"
               title="Добавить новый аккаунт"
               ok-text="Ok"
               cancel-text="Cancel"
               @ok="addAccountSubmit"
               :footer="null"
               class="add-account-modal"
               width="680px"
               :destroyOnClose="true"
      >
        <a-form-model
          ref="addAccountForm"
          :model="addAccountForm"
          :rules="addAccountRules"
        >
          <a-row>
            <a-col :span="12" class="pr-1 mt-0">
              <a-form-model-item ref="name" label="Имя аккаунта" prop="name">
                <a-input v-model="addAccountForm.name" placeholder="Например Иван Иванов" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" class="pl-1 mt-0">
              <a-form-model-item ref="psystem_id" label="Тип аккаунта" prop="psystem_id">
                <a-select v-model="addAccountForm.psystem_id" placeholder="Укажите тип аккаунта" @change="psHandleChange">
                  <a-select-option v-for="ps in psystems" :key="ps.id">
                    {{ ps.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item ref="login" label="Login аккаунта (№ телефона без знака +)" prop="login">
            <a-input v-model="addAccountForm.login" placeholder="Например 79876543210" />
          </a-form-model-item>
          <a-row>
            <a-col :span="17" class="pr-1 pt-0">
              <a-form-model-item ref="token" label="Token аккаунта" prop="token">
                <a-input v-model="addAccountForm.token" placeholder="Укажите токен аккаунта" />
              </a-form-model-item>
            </a-col>
            <a-col :span="7" class="pl-1 text-right" style="">
              <div style="padding-top: 2.2rem">
                <a-tooltip title="Перейдите по ссылке https://qiwi.com/api. Нажмите 'Выпустить новый токен'. Скопируйте полученный токен и вставьте в поле формы." placement="right">
                  <a href="https://qiwi.com/api" target="_blank">https://qiwi.com/api </a>
                  <a-icon type="question-circle" class="text-primary font-size-18" />
                </a-tooltip>
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="17" class="pr-1 pt-0">
              <a-form-model-item ref="secret" label="SecretKey аккаунта" prop="secret">
                <a-input v-model="addAccountForm.secret" placeholder="Укажите SecretKey аккаунта" :disabled="addAccountForm.token.length<1 || addAccountForm.login.length<1" />
              </a-form-model-item>
            </a-col>
            <a-col :span="7" class="pl-1 text-right" style="">
              <div style="padding-top: 1.9rem" v-if="addAccountForm.token.length>0 && addAccountForm.login.length>0">
                <a-tooltip title="SecretKey необходим для выставления P2P счетов. Заполните поля выше и нажмите 'Получить SecretKey'." placement="right">
                  <a-button :loading="secretLoading" @click="receivePairKeys">Получить SecretKey</a-button>
                </a-tooltip>
              </div>
            </a-col>
          </a-row>
        </a-form-model>
        <a-row>
          <a-col :span="14">
            <div v-if="addAccountCheckLoading" class="font-size-12">
              <div v-if="addAccountCheckError">
                <span class="text-danger"><a-icon type="close" /> Ошибка. Проверьте данные</span>
              </div>
              <div v-else>
                <span><a-icon type="loading" /> Проверка. Запрос баланса...</span>
                <span v-if="addAccountRestrictionsLoading"><a-icon type="loading" /> | Запрос ограничений...</span>
              </div>
            </div>
          </a-col>
          <a-col :span="10" class="text-right">
            <a-button type="primary" @click="addAccountCheck" :loading="addAccountSubmitLoading">
              Добавить
            </a-button>
            <a-button style="margin-left: 1rem;" @click="closeAddAccountModal">
              Отмена
            </a-button>
          </a-col>
        </a-row>
      </a-modal>
      <a-modal v-model="withdrawModal"
               title="Вывод средств"
               ok-text="Ok"
               cancel-text="Cancel"
               @ok="withdrawSubmit"
               :footer="null"
               class="withdraw-modal"
               width="520px"
               :destroyOnClose="true"
      >
        <a-form-model
          ref="withdrawForm"
          :model="withdrawForm"
          :rules="withdrawRules"
        >
          <a-form-model-item ref="provider_id" label="Тип перевода" prop="provider_id">
            <a-radio-group v-model="withdrawForm.provider_id" :default-value="withdrawForm.provider_id" button-style="solid">
              <a-radio-button value="99">
                <span class="font-weight-bold text-warning">Q</span> QIWI Wallet
              </a-radio-button>
              <a-radio-button value="1963">
                <a-icon type="credit-card" class="text-primary" /> VISA
              </a-radio-button>
              <a-radio-button value="21013">
                <a-icon type="credit-card" class="text-danger" /> MasterCard
              </a-radio-button>
              <a-radio-button value="31652">
                <a-icon type="credit-card" class="text-success" /> МИР
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item ref="pay_account" label="Номер счета/карты получателя" prop="pay_account">
            <a-input v-model="withdrawForm.pay_account" placeholder="" />
          </a-form-model-item>
          <a-form-model-item ref="amount" label="Сумма платежа" prop="amount">
            <!--            <a-input v-model="withdrawForm.amount" placeholder="" />-->
            <a-input-number
              v-model="withdrawForm.amount"
              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
              style="width: 100%"
            />
          </a-form-model-item>
        </a-form-model>
        <a-row>
          <a-col :span="12">
            <div class="font-size-12">
              <p class="font-weight-bold">Информация о комиссии:
                <a-button size="small" @click="getWithdrawCommissions()" :loading="withdrawCommissionsLoading">
                  <a-icon type="reload" class="text-warning" />
                </a-button></p>
              <p>
                <span>Сумма к получению: </span><span>{{ withdrawComm.incomeSum }}</span> ₽<br/>
                <span>Размер комиссии: </span><span>{{ withdrawComm.commissionSum }}</span> ₽<br/>
                <span>Итоговая сумма: </span><span class="font-weight-bold">{{ withdrawComm.withdrawSum }}</span> ₽
              </p>
            </div>
          </a-col>
          <a-col :span="12" class="text-right">
            <div class="mt-5">
              <a-button type="primary" @click="withdrawSubmit" :loading="withdrawSubmitLoading">
                Отправить
              </a-button>
              <a-button style="margin-left: 1rem;" @click="closeWithdrawModal">
                Отмена
              </a-button>
            </div>
          </a-col>
        </a-row>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import moment from 'moment-timezone'
// import userProfile from '@/views/apps/users/profile.vue'

const accountColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
  {
    title: 'Аккаунт',
    dataIndex: 'name',
    key: 'name',
    width: '15%',
    scopedSlots: { customRender: 'account' },
  },
  {
    title: 'Тип',
    dataIndex: 'psystem_id',
    key: 'psystem_id',
    width: '10%',
    scopedSlots: { customRender: 'psystem' },
  },
  {
    title: 'Транзакций',
    dataIndex: 'transactions_count',
    key: 'transactions_count',
    width: '7%',
    scopedSlots: { customRender: 'transactions_count' },
  },
  {
    title: 'В ротации',
    dataIndex: 'in_rotate',
    key: 'in_rotate',
    align: 'center',
    scopedSlots: { customRender: 'rotate' },
  },
  {
    title: 'Баланс',
    dataIndex: 'balance',
    key: 'balance',
    width: '12%',
    scopedSlots: { customRender: 'balance' },
  },
  {
    title: 'Начислено',
    dataIndex: 'sum',
    key: 'sum',
    width: '12%',
    scopedSlots: { customRender: 'income' },
  },
  {
    title: 'Выведено',
    dataIndex: 'sum',
    key: 'sum',
    width: '12%',
    scopedSlots: { customRender: 'outcome' },
  },
  {
    title: 'Заблокирован',
    dataIndex: 'is_banned',
    key: 'is_banned',
    align: 'center',
    width: '10%',
    scopedSlots: { customRender: 'is_banned' },
  },
  {
    title: 'Действие',
    dataIndex: 'operation',
    width: '10%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'index',
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      accountsData: [],
      accountColumns,
      moment,
      rotateLoading: false,
      rotateId: undefined,
      restrictionsLoading: false,
      restrictionsId: undefined,
      psystems: [],
      newAccountModal: false,
      withdrawModal: false,
      secretLoading: false,
      addAccountSubmitLoading: false,
      addAccountCheckLoading: false,
      addAccountCheckError: false,
      addAccountRestrictionsLoading: false,
      withdrawSubmitLoading: false,
      withdrawCommissionsLoading: false,
      withdrawAccountID: 0,
      addAccountForm: {
        name: '',
        psystem_id: 1,
        login: '',
        token: '',
        secret: '',
        secret2: '',
        webhook_url: '',
        balance: 0,
        in_rotate: 1,
        is_banned: 0,
      },
      withdrawForm: {
        provider_id: '99',
        pay_account: '',
        amount: 100,
        commissionSum: 0,
        total_amount: 100,
      },
      withdrawComm: {
        withdrawSum: 0,
        incomeSum: 0,
        commissionSum: 0,
      },
      // withdrawComm: undefined,
      addAccountRules: {
        name: [{ required: true, message: 'Укажите имя аккаунта', trigger: 'change' }],
        psystem_id: [{ required: true, message: 'Выберите платежную систему', trigger: 'change' }],
        login: [{ required: true, message: 'Укажите login аккаунта', trigger: 'change' }],
        token: [{ required: true, message: 'Укажите token аккаунта', trigger: 'change' }],
        secret: [{ required: true, message: 'Укажите SecretKey для счетов', trigger: 'change' }],
      },
      withdrawRules: {
        provider_id: [{ required: true, message: 'Укажите провайдера платежа', trigger: 'change' }],
        pay_account: [{ required: true, message: 'Укажите номер счета/карты получателя', trigger: 'change' }],
        amount: [{ required: true, message: 'Укажите сумму платежа', trigger: 'change' }],
      },
    }
  },
  mounted () {
    this.getAccounts()
  },
  methods: {
    showAccountModal() {
      this.newAccountModal = true
      this.getPS()
    },
    showWithdrawModal(accountID) {
      this.withdrawAccountID = accountID
      this.withdrawModal = true
    },
    closeAddAccountModal() {
      this.newAccountModal = false
    },
    closeWithdrawModal() {
      this.withdrawModal = false
    },
    getWithdrawCommissions() {
      console.log(this.withdrawAccountID)
      this.withdrawCommissionsLoading = true
      const url = '/admin/accounts/' + this.withdrawAccountID + '/paycom'
      apiClient.post(url, this.withdrawForm).then((response) => {
        this.withdrawComm = response.data.data
        this.withdrawCommissionsLoading = false
      }).catch(error => {
        console.log(error)
        this.withdrawCommissionsLoading = false
      })
    },
    withdrawSubmit() {
      this.$refs.withdrawForm.validate(valid => {
        if (valid) {
          // ---
          this.withdrawSubmitLoading = true
          const url = '/admin/accounts/' + this.withdrawAccountID + '/payment'
          return apiClient.post(url, this.withdrawForm).then((response) => {
            this.addAccountForm.balance = response.data.data.amount
            this.$notification.success({
              message: 'Выполнен денежный перевод',
              description: 'Получатель: ' + response.data.data.account,
            })
            this.withdrawSubmitLoading = false
            this.closeWithdrawModal()
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: 'Ошибка при переводе средств',
              description: error.message,
            })
            this.withdrawSubmitLoading = false
          })
        }
      })
    },
    psHandleChange(e) {
      const psIndex = this.psystems.findIndex(obj => obj.id === e)
      this.addAccountForm.webhook_url = this.psystems[psIndex].webhook_url
      // console.log(this.psystems[psIndex].webhook_url)
    },
    setWebHookUrl() {
      const psIndex = this.psystems.findIndex(obj => obj.id === this.addAccountForm.psystem_id)
      this.addAccountForm.webhook_url = this.psystems[psIndex].webhook_url
      console.log(this.addAccountForm.webhook_url)
    },
    receivePairKeys() {
      this.secretLoading = true
      this.setWebHookUrl()
      const url = '/admin/accounts/p2p_keys'
      return apiClient.post(url, this.addAccountForm).then((response) => {
        this.$notification.success({
          message: 'Получены P2P ключи',
          description: 'Name: ' + response.data.data.name,
        })
        this.addAccountForm.secret = response.data.data.secretKey
        this.secretLoading = false
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при получении P2P ключей',
          description: error.message,
        })
        this.secretLoading = false
      })
    },
    addAccountCheck() {
      this.$refs.addAccountForm.validate(valid => {
        if (valid) {
          this.addAccountCheckError = false
          this.addAccountRestrictionsLoading = false
          this.addAccountSubmitLoading = true
          this.addAccountCheckLoading = true
          const url = '/admin/accounts/receive_balance'
          return apiClient.post(url, this.addAccountForm).then((response) => {
            this.addAccountForm.balance = response.data.data.amount
            this.addAccountRestrictionsLoading = true
            this.addAccountCheckRestrictions()
            // this.addAccountSubmit()
          }).catch(error => {
            console.log(error)
            this.addAccountCheckError = true
            this.addAccountSubmitLoading = false
          })
        }
      })
    },
    addAccountCheckRestrictions() {
      const url = '/admin/accounts/receive_restrictions'
      return apiClient.post(url, this.addAccountForm).then((response) => {
        this.addAccountForm.is_banned = +response.data.locked
        this.addAccountSubmit()
        this.addAccountRestrictionsLoading = false
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при запросе ограничений',
          description: error.message,
        })
        this.addAccountSubmitLoading = false
        this.addAccountCheckLoading = false
        this.addAccountRestrictionsLoading = false
      })
    },
    addAccountSubmit() {
      this.setWebHookUrl()
      const url = '/admin/accounts/store'
      return apiClient.post(url, this.addAccountForm).then((response) => {
        this.$notification.success({
          message: 'Создан новый аккаунт',
          description: response.data.data.name,
        })
        this.accountsData.push(response.data.data)
        this.addAccountSubmitLoading = false
        this.addAccountCheckLoading = false
        this.addAccountRestrictionsLoading = false
        this.closeAddAccountModal()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при создании аккаунта',
          description: error.message,
        })
        this.addAccountSubmitLoading = false
        this.addAccountCheckLoading = false
        this.addAccountRestrictionsLoading = false
      })
    },
    deleteAccount(acc) {
      const url = '/admin/accounts/' + acc.id
      apiClient.delete(url).then((response) => {
        console.log(response.data)
        // if ((response.data.error) && (!data.error.message))
        this.$notification.success({
          message: 'Аккаунт удален',
          description: acc.name,
        })
        const accIndex = this.accountsData.findIndex(obj => obj.id === acc.id)
        this.accountsData.splice(accIndex, 1)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при удалении аккаунта',
          description: error.message,
        })
      })
    },
    changeRotation(accountID, status) {
      // const confMsg = ['Вывести аккаунт из ротации', 'Поместить аккаунт в ротацию']
      this.rotateLoading = true
      this.rotateId = accountID
      const notiMsg = ['Выведен из ротации', 'Помещен в ротацию']
      const url = '/admin/accounts/' + accountID + '/rotation/' + status
      apiClient.post(url).then((response) => {
        console.log(response.data)
        // if ((response.data.error) && (!data.error.message))
        this.$notification.success({
          message: notiMsg[status],
          description: 'Аккаунт "' + response.data.data.name + '"',
        })
        const accIndex = this.accountsData.findIndex(obj => obj.id === accountID)
        this.accountsData[accIndex].in_rotate = status
        this.rotateLoading = false
        // this.$router.push('/banners')
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Не удалось изменить статус аккаунта',
          description: error.message,
        })
        this.rotateLoading = false
      })
    },
    checkRestrictions(accountID) {
      // ---
      this.restrictionsLoading = true
      this.restrictionsId = accountID
    },
    getAccounts() {
      const url = '/admin/accounts'
      apiClient.get(url).then((response) => {
        this.accountsData = response.data.data
      }).catch(error => { console.log(error) })
    },
    getPS() {
      const url = '/admin/ps'
      apiClient.get(url).then((response) => {
        this.psystems = response.data.data
      }).catch(error => { console.log(error) })
    },
  },
}
</script>

<style scoped>

</style>
